import React from "react";
import { graphql } from "gatsby";
import Loadable from "@loadable/component";
import SliderHeaderHomeV2 from "../fasce/slide-header-home-v2";
import SlideHeader from "../fasce/slide-header";
import EditorialeGenerico from "../fasce/editoriale-generico";
import WidgetTestuale from "../fasce/widget-testuale";
import BottoneCtaIsolato from "../fasce/bottone-cta-isolato";
import CaratteristichePuzzle from "../fasce/caratteristiche-puzzle";
import Intro from "../fasce/intro";
import TessereParticulier from "../fasce/tessere-particulier";
import Faq from "../fasce/faq";
import OpzioniProdotto from "../fasce/opzioni-prodotto";
import Accordion from "../fasce/accordion";
import BadgeWidget from "../fasce/badge-widget";
import HubGridItem from "../fasce/hub-grid-item";
import Hub1Item from "../fasce/hub1-item";
import Timeline from "../fasce/timeline";
import RecensioniVerificateWidget from "../fasce/recensioni-verificate-widget";
import RecensioniVerificateReviews from "../fasce/recensioni-verificate-reviews";
import Motivazioni from "../fasce/motivazioni";
import IconListParagraph from "../fasce/icon-list-paragraph";
import ScopriDipiu from "../fasce/scopri-di-piu";
import CallCation3Items from "../fasce/call-action-3-items";
import ElencoDocumenti from "../fasce/elenco-documenti";
import Editoriale from "../fasce/editoriale";
import AppBadge from "../fasce/app-badge";
import Modale from "../fasce/modale";
import HubCentriAssistenza from "../fasce/hub-centri-assistenza";
import CardLayout from "../fasce/card-layout";
import FormAutolettura from "../fasce/form-autolettura";
import HtmlContent from "../fasce/html-content";
import SliderFaq from "../fasce/slider-faq";
import EntryPointProcessiInself from "../fasce/entry-point-processi-inself";
import FormContatti from "../fasce/form-contatti";
import HubProdotto from "../fasce/hub-prodotto";
import FlussoSwitchIn from "../fasce/flusso-switch-in";

const ServizioTutelaGas = Loadable(() => import("../fasce/servizio-tutela-gas"));
const WidgetContatti = Loadable(() => import("../fasce/widget-contatti"));

const FasciaPaginaGenerica = ({ fascia, ...other }) => {
  switch (fascia.__typename) {
    case "LiferaySliderHeaderHomeV2":
      return <SliderHeaderHomeV2 data={fascia} {...other} />;
    case "LiferaySlideHeader":
      return <SlideHeader data={fascia} {...other} />;
    case "LiferayEditorialeGenerico":
      return <EditorialeGenerico data={fascia} {...other} />;
    case "LiferayWidgetTestuale":
      return <WidgetTestuale data={fascia} {...other} />;
    case "LiferayBottoneCtaIsolato":
      return <BottoneCtaIsolato data={fascia} {...other} />;
    case "LiferayCaratteristichePuzzle":
      return <CaratteristichePuzzle data={fascia} {...other} />;
    case "LiferayIntro":
      return <Intro data={fascia} {...other} />;
    case "LiferayTessereParticulier":
      return <TessereParticulier data={fascia} {...other} />;
    case "LiferayFaq":
      return <Faq data={fascia} {...other} />;
    case "LiferayWidgetContatti":
      return <WidgetContatti data={fascia} {...other} />;
    case "LiferayOpzioniProdotto":
      return <OpzioniProdotto data={fascia} {...other} />;
    case "LiferayAccordion":
      return <Accordion data={fascia} {...other} />;
    case "LiferayBadgeWidget":
      return <BadgeWidget data={fascia} {...other} />;
    case "LiferayHubGridItem":
      return <HubGridItem data={fascia} {...other} />;
    case "LiferayHub1Item":
      return <Hub1Item data={fascia} {...other} />;
    case "LiferayTimeline":
      return <Timeline data={fascia} {...other} />;
    case "LiferayRecensioniVerificateWidget":
      return <RecensioniVerificateWidget data={fascia} {...other} />;
    case "LiferayRecensioniVerificateReviews":
      return <RecensioniVerificateReviews data={fascia} {...other} />;
    case "LiferayMotivazioni":
      return <Motivazioni data={fascia} {...other} />;
    case "LiferayIconListParagraph":
      return <IconListParagraph data={fascia} {...other} />;
    case "LiferayScopriDiPiu":
      return <ScopriDipiu data={fascia} {...other} />;
    case "LiferayCallAction3Items":
      return <CallCation3Items data={fascia} {...other} />;
    case "LiferayElencoDocumenti":
      return <ElencoDocumenti data={fascia} {...other} />;
    case "LiferayEditoriale":
      return <Editoriale data={fascia} {...other} />;
    case "LiferayJskAppBadge":
      return <AppBadge data={fascia} {...other} />;
    case "LiferayJskTutelaGas":
      return <ServizioTutelaGas data={fascia} {...other} />;
    case "LiferayModale":
      return <Modale data={fascia} {...other} />;
    case "LiferayJskHubCentriAssistenza":
      return <HubCentriAssistenza data={fascia} {...other} />;
    case "LiferayJskCardLayout":
      return <CardLayout data={fascia} {...other} />;
    case "LiferayJskFormAutolettura":
      return <FormAutolettura data={fascia} {...other} />;
    case "LiferayJskHtmlContent":
      return <HtmlContent data={fascia} {...other} />;
    case "LiferayJskSliderFaq":
      return <SliderFaq data={fascia} {...other} />;
    case "LiferayJskEntryPointProcessiInself":
      return <EntryPointProcessiInself data={fascia} {...other} />;
    case "LiferayJskFormContatti":
      return <FormContatti data={fascia} {...other} />;
    case "LiferayJskHubProdotto":
      return <HubProdotto data={fascia} {...other} />;
    case "LiferayJskFlussoSwitchIn":
      return <FlussoSwitchIn data={fascia} {...other} />;
    default:
      console.warn(`Cannot handle ${fascia.__typename} type for pagina generica`);
      return null;
  }
};

export default FasciaPaginaGenerica;
export const query = graphql`
  fragment FasciaPaginaGenericaFragment on LiferayWebContent {
    __typename
    ...SliderHeaderHomeV2Fragment
    ...SlideHeaderFragment
    ...EditorialeGenericoFragment
    ...WidgetTestualeFragment
    ...BottoneCtaIsolatoFragment
    ...CaratteristichePuzzleFragment
    ...IntroFragment
    ...TessereParticulierFragment
    ...FaqFragment
    ...WidgetContattiFragment
    ...OpzioniProdottoFragment
    ...AccordionFragment
    ...BadgeWidgetFragment
    ...HubGridItemFragment
    ...Hub1ItemFragment
    ...TimelineFragment
    ...RecensioniVerificateWidgetFragment
    ...RecensioniVerificateReviewsFragment
    ...MotivazioniFragment
    ...IconListParagraphFragment
    ...ScopriDiPiuFragment
    ...CallAction3ItemsFragment
    ...ElencoDocumentiFragment
    ...EditorialeFragment
    ...JskAppBadgeFragment
    ...JskTutelaGasFragment
    ...ModaleFragment
    ...JskHubCentriAssistenzaFragment
    ...JskCardLayoutFragment
    ...JskFormAutoletturaFragment
    ...JskHtmlContentFragment
    ...JskSliderFaqFragment
    ...JskEntryPointProcessiInselfFragment
    ...FormContattiFragment
    ...JskHubProdottoFragment
    ...JskFlussoSwitchInFragment
  }
`;
